<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
        padding-left: 24px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>工作台</a-breadcrumb-item>
              <a-breadcrumb-item>系统设置</a-breadcrumb-item>
              <a-breadcrumb-item>修改密码</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0 }"
      >
        <a-form
          :form="form"
          style="width: 630px; margin: 0 auto"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="原密码">
            <a-input
              placeholder="请输入原密码"
              type="password"
              v-model="form.oldPassword"
            />
          </a-form-item>
          <a-form-item label="新密码">
            <a-input
              placeholder="请输入新密码"
              type="password"
              v-model="form.password"
            />
          </a-form-item>
          <a-form-item label="确认密码">
            <a-input
              placeholder="请输入确认密码"
              type="password"
              @blur="onapassword"
              v-model="form.password2"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 10, offset: 0 }">
            <a-button type="primary" @click="onSubmit"> 提交 </a-button>
          </a-form-item>
        </a-form>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import http from '../../../http'
export default {
  data() {
    return {
      logo: [],
      loading: false,
      imageUrl: '',
      fill: null,
      labelCol: { span: 11 },
      wrapperCol: { span: 14 },
      form: {
        oldPassword: '',
        password: '',
        password2: '',
      },
    }
  },
  methods: {
    onapassword() {
      if (this.form.password != this.form.password2) {
        this.$message.error('两次输入密码不一致，请重新输入')
        this.form.password2 = ''
      }
    },
    onSubmit() {
      if (
        this.form.oldPassword != '' &&
        this.form.password2 != '' &&
        this.form.password != ''
      ) {
        if (this.form.password == this.form.password2) {
          this.putauthpassword()
        }
      }
    },
    async getuser() {
      try {
        const res = await http.getuser()
        const { success, data } = res.data
        if (success) {
          console.log(data)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onout() {
      this.getout()
      this.$router.replace({ path: '/login' })
    },
    async getout() {
      try {
        const res = await http.getlogout()
        const { success } = res.data
        if (success) {
          this.$message.success('注销成功')
          sessionStorage.setItem('store', '')
          this.$router.push({ name: 'login' })
        }
        // console.log(res);
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async putauthpassword() {
      try {
        const res = await http.putauthpassword(this.form)
        const { success, msg } = res.data
        if (success) {
          this.onout()
          this.$message.success('修改密码成功')
          //   console.log(data);
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
  },
  mounted() {},
}
</script>
<style scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.flow-d-r {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
/* .ant-form-item {
  height: 60px;
  margin-bottom: 15px;
} */
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ant-page-header {
  padding: 0;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
::v-deep .ant-form-item-label {
  text-align: left;
  padding-left: 3px;
}
</style>
